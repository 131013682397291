import React from 'react';
import Container from '../../components/Container';
import SocialLinkButton from '../../components/SocialLinkButton';
import './styles.scss';

const Home = () => {
  return (
    <Container>
      <div className="home-header">
        <h1 className="name-header">
          <div className="waving-hand">
            <span role="img" aria-label="waving-hand">
              👋🏻
            </span>
          </div>
          <div>Hi, I'm Jamie.</div>
        </h1>
        <div className="social">
          <SocialLinkButton name="linkedin" />
          <SocialLinkButton name="github" />
        </div>
      </div>
      <div className="bio">
        <p>
          I'm a full stack web developer with experience primarily using React, Node.js, MongoDB,
          and PostgreSQL.
        </p>
        <p>
          This website is a work in progress. I'll be updating it with projects I've worked on soon.
          For now, check out my LinkedIn for my resume.
        </p>
      </div>
    </Container>
  );
};

export default Home;
