import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './App.scss';
import Home from './views/Home';
import Footer from './components/Footer';

// Build FontAwesome icon library.
library.add(fab);

function App() {
  return (
    <div className="App">
      <Router>
        {/* Nav */}

        {/* Main Page Content */}
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>

        {/* Footer */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
