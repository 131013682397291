import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';

const socialSites = {
  linkedin: {
    icon: 'linkedin-in',
    background: '#2867b2',
    url: 'https://www.linkedin.com/in/jameson-mcbride',
  },
  github: {
    icon: 'github',
    background: '#24292E',
    url: 'https://github.com/jameson-m',
  },
  twitter: {
    icon: 'twitter',
    background: '#1da1f2',
    url: 'https://twitter.com/Jamesonmcbride1',
  },
};

const SocialLinkButton = ({ name }) => {
  const { icon, background, url } = socialSites[name];
  return (
    <a href={url}>
      <FontAwesomeIcon
        className="social-link-button"
        icon={[ 'fab', icon ]}
        style={{ background, padding: '4px 6px', borderRadius: '25%', fontSize: '24px' }}
        inverse
      />
    </a>
  );
};

SocialLinkButton.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SocialLinkButton;
